
import { FormPage } from '@/mixins'
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import CategoryEditor from './CategoryEditor.vue'

@Component({
  components: {
    CategoryEditor
  }
})

export default class BranchCategoryEditor extends Mixins(FormPage) {
  @Prop({ required: true })
  private readonly branchId!: string;

  @Prop({ required: true })
  private readonly value!: CategoryModel[];

  selectCategory (selection: CategoryModel): void {
    // const category = selection.Category
    if (!(this.value && selection)) return
    const alreadyInCategories = this.value.find((cat) => cat.CategoryId === selection.CategoryId)
    if (alreadyInCategories !== undefined) {
      return
    }

    this.loading = true

    Services.API.Businesses.AddBusinessBranchCategory(this.branchId, selection.CategoryId)
      .then((returnModel) => {
        if (!this.value) return
        const newValue = [...this.value, returnModel]
        this.$emit('input', newValue)
        // this.categories.push(returnModel)
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.loading = false })
  }

  removeCategory (categoryId: string): void {
    if (!(this.value && categoryId)) return
    this.loading = true

    Services.API.Businesses.DeleteBusinessBranchCategory(this.branchId, categoryId)
      .then((removedCat) => {
        if (!(this.value)) return
        const index = this.value.findIndex((item) => item.CategoryId === removedCat.CategoryId)
        if (index === -1) {
          console.error('Category index not found')
          return
        }
        // this.categories.splice(index, 1)
        const newValue = this.value
        newValue.splice(index, 1)
        this.$emit('input', newValue)
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.loading = false })
  }
}

