
import { InputCheckbox, InputNumber, InputText, InputTextarea } from '@/components/inputs'
import { FormPage } from '@/mixins'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { BusinessBranchModel } from '../../../../../tradingmate-modules/src/models/api/businesses'
import FormCheckboxField from '@/components/forms/FormCheckboxField.vue'

@Component({
  components: {
    InputCheckbox,
    FormCheckboxField,
    InputNumber,
    InputText,
    InputTextarea
  }
})

export default class BrandingEditor extends Mixins(FormPage) {
  @Prop({ required: true })
  private value!: BusinessBranchModel;

  private abnDebounce: number | undefined = undefined

  private npcDebounce: number | undefined = undefined

  updateAbn (): void {
    console.log('update abn')
    window.clearTimeout(this.abnDebounce)
    if (!this.value) return
    this.abnDebounce = window.setTimeout(() => {
      Services.API.Businesses.UpdateAbn(
        this.value.BusinessBranchId,
        { ABN: this.value.ABN }
      ).catch((error) => { this.httpError = error })
    }, 1000)
  }

  updateNonProfitCharity (): void {
    window.clearTimeout(this.npcDebounce)
    if (!this.value) return
    this.abnDebounce = window.setTimeout(() => {
      Services.API.Businesses.UpdateNpc(
        this.value.BusinessBranchId,
        { NonProfitCharity: this.value.NonProfitCharity }
      ).catch((error) => { this.httpError = error })
    }, 1000)
  }
}

