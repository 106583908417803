
import { FormPage } from '@/mixins'
import { Component, Mixins, Watch } from 'vue-property-decorator'
// import CategoryEditor, { CategoryAutocompleteResult } from '@/components/forms/fields/FieldCategoryEditor.vue'
import ImageGallery from '@/components/inputs/ImageGallery.vue'
import { BrandingEditor, CategoryEditor, ContactMethodEditor, OpeningHoursEditor, ServiceOfferedEditor, SocialLinkEditor } from '@/components/collections/business'
import LicenseQualificationEditor from '@/components/collections/business/LicenseQualificationEditor.vue'
import { CategorySelector, InputText, InputTextarea } from '@/components/inputs'
import AttachedObjectType from 'tradingmate-modules/src/models/api/files/AttachedObjectType'
import { FileAttachmentType } from 'tradingmate-modules/src/models/api/files'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import BranchCategoryEditor from '@/components/collections/business/BranchCategoryEditor.vue'
import { Config } from 'tradingmate-modules/src/environment'
import BranchDetailsEditor from '@/components/collections/business/BranchDetailsEditor.vue'

@Component({
  components: {
    BranchDetailsEditor,
    ImageGallery,
    OpeningHoursEditor,
    CategoryEditor,
    SocialLinkEditor,
    ContactMethodEditor,
    ServiceOfferedEditor,
    LicenseQualificationEditor,
    InputText,
    InputTextarea,
    BrandingEditor,
    CategorySelector,
    BranchCategoryEditor
  }
})
export default class BusinessBranchEdit extends Mixins(FormPage) {
  private id = this.$route.params.id
  private objectType = AttachedObjectType.BusinessBranch;
  private fileAttachmentTypeLogo = FileAttachmentType.Logo;
  private fileAttachmentTypeGallery = FileAttachmentType.ImageGalleryItem;

  private branch: BusinessBranchModel | null = null

  mounted (): void {
    this.getBranch()
  }

  scrollFix (): void {
    // window.scroll(100, 200)
    // const hash = this.$route.hash.toString()
    const id = this.$route.fullPath.split('#').pop()
    if (!id) return
    // console.log(id)
    // console.log(hash)
    const el = document.getElementById(id)
    // console.log(el)
    // console.log(el?.offsetTop)
    if (el) {
      console.log(el)
      const y = el.offsetTop
      console.log(y)
      el.scrollIntoView()
      // window.scroll(20, y + 200)
      // window.scroll(0, el.offsetTop)
    }
    // location.hash = this.$route.hash
  }

  @Watch('$route.hash')
  handleRouteChange (): void {
    setTimeout(() => this.scrollFix(), 1)
    // this.$route.hash
  }

  getBranch (): void {
    Services.API.Businesses.GetBranch(this.id)
      .then((response) => {
        setTimeout(() => this.scrollFix(), 1)
        this.branch = response
      }).catch((error) => {
        this.httpError = error
      }).finally(() => {
        this.loading = false
      })
  }

  onPreviewClicked (): void {
    if (!this.branch) return
    var url = Config.WebsiteUrls.Public + '/members/' + this.branch.Slug
    window.open(url, '_self')
  }
}
